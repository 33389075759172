<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Determine which of the salts listed below would result in acidic, basic, or neutral
        solutions when dissolved in pure water.
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px">Salt</th>
            <th style="font-size: 15px">Acidic</th>
            <th style="font-size: 15px">Neutral</th>
            <th style="font-size: 15px">Basic</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><chemical-latex content="LiBr" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="acidic" value="acidic" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="neutral" value="neutral" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="basic" value="basic" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <chemical-latex content="NH4(ClO4)" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="acidic" value="acidic" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="neutral" value="neutral" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="basic" value="basic" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td><chemical-latex content="Na2S" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="acidic" value="acidic" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="neutral" value="neutral" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="basic" value="basic" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td><chemical-latex content="K2CO3" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="acidic" value="acidic" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="neutral" value="neutral" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="basic" value="basic" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td><chemical-latex content="KBr" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="acidic" value="acidic" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="neutral" value="neutral" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="basic" value="basic" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td><chemical-latex content="CH3COONa" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="acidic" value="acidic" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="neutral" value="neutral" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="basic" value="basic" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question144',
  components: {
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
      options: [
        {salt: '$\\ce{LiBr}$', value: 'LiBr'},
        {salt: '$\\ce{NH4(ClO4)}$', value: 'NH4ClO4'},
        {salt: '$\\ce{Na2S}$', value: 'Na2S'},
        {salt: '$\\ce{K2CO3}$', value: 'K2CO3'},
        {salt: '$\\ce{KBr}$', value: 'KBr'},
        {salt: '$\\ce{CH3COONa}$', value: 'CH3COONa'},
      ],
    };
  },
};
</script>
